import Join from './Join'

function Home () {
  return (
      <>
        <Join />
      </>
  )
}

export default Home
